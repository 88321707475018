import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="timeout-auto-clear"
export default class extends Controller {
  connect () {
  }

  disconnect () {
    this.#cancelTimer()
  }

  resetTimeout () {
    this.#cancelTimer()
    this.timerId = setTimeout(() => {
      this.element.value = ''
      this.dispatch('cleared')
    }, 5000)
  }

  #cancelTimer () {
    if (this.timerId) {
      clearTimeout(this.timerId)
      this.timerId = undefined
    }
  }
}
