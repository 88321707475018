import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="refresh-page"
export default class extends Controller {
  // if there is a "noRefreshId" value, and if this id is the same in the next page,
  // we don't refresh the page and only restart the timer
  static values = { delay: { type: Number, default: 8000 }, noRefreshId: { type: String } }

  connect () {
    if (this.hasNoRefreshIdValue) {
      this.boundBeforeRender = this.#beforeRender.bind(this)
      document.addEventListener('turbo:before-render', this.boundBeforeRender)
    }

    this.timeoutID = setTimeout(() => {
      if (!this.element.checkVisibility()) return

      Turbo.visit(window.location, { action: 'replace' })
    }, this.delayValue)
  }

  disconnect () {
    if (this.hasNoRefreshIdValue) {
      document.removeEventListener('turbo:before-render', this.boundBeforeRender)
    }

    clearTimeout(this.timeoutID)
  }

  #beforeRender (event) {
    event.detail.render = (_currentBody, newBody) => {
      if (newBody.querySelector(`[data-refresh-page-no-refresh-id-value="${this.noRefreshIdValue}"]`)) {
        this.disconnect()
        this.connect()
      } else {
        document.body.replaceWith(newBody)
      }
    }
  }
}
