import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

// Connects to data-controller="confirmation-modal"
export default class extends Controller {
  static targets = ['modal', 'link', 'form', 'overlay']

  show (event) {
    event.preventDefault()
    event.stopPropagation()

    if (event.currentTarget.href) { // we are in the case of a link
      const initialLink = event.currentTarget
      const newLink = this.linkTarget.querySelector('a')

      newLink.href = initialLink.href
      if (initialLink.dataset.method) newLink.dataset.method = initialLink.dataset.method

      this.linkTarget.classList.remove('hidden')
    } else { // we are in the case of a form
      const initialForm = event.currentTarget.closest('form')
      const initialButton = initialForm.querySelector('button')
      const newForm = this.formTarget.querySelector('form')
      const newButton = this.formTarget.querySelector('button')

      newButton.name = event.currentTarget.name
      newButton.value = event.currentTarget.value

      if (initialButton.dataset.turboFrame) {
        newButton.dataset.turboFrame = initialButton.dataset.turboFrame
      }

      if (newForm) {
        newForm.action = initialForm.getAttribute('action')
        newForm.method = initialForm.getAttribute('method')
      }

      this.formTarget.classList.remove('hidden')
    }

    this.modalTarget.dataset.displayed = ''
    enter(this.overlayTarget)
  }

  cancel () {
    leave(this.overlayTarget).then(() => {
      delete this.modalTarget.dataset.displayed
    })
  }
}
