import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // the optional inputTarget allows to attach the Numpad to an input by default
  static targets = ['input']
  static values = {
    separator: { type: String, default: '.' }
  }

  connect () {
    if (this.hasInputTarget) this.inputElement = this.inputTarget
    this.currentValue = []
    this.element.focus()
  }

  selectInput (event) {
    this.currentValue = []
    this.inputElement = event.currentTarget
  }

  clearInput () {
    this.currentValue = []
  }

  keyDown (event) {
    event.preventDefault()

    if (event.key === 'Backspace') {
      this.clickBackspace(event)
    } else if (event.key.match(/[0-9]/)) {
      this.#processKey(event.key)
    }
  }

  clickNumber (event) {
    event.preventDefault()
    this.#processKey(event.target.dataset.value)
  }

  clickBackspace (event) {
    event.preventDefault()
    this.#focusBlur(() => {
      this.currentValue.pop()
      this.#updateInput()
    })
  }

  #processKey (key) {
    if (key === '00') {
      this.currentValue.push('0', '0')
    } else {
      this.currentValue.push(key)
    }

    this.#updateInput()
  }

  #updateInput () {
    if (this.currentValue.length === 0) {
      this.inputElement.value = ''
    } else {
      this.inputElement.value = this.#formatValue()
    }

    this.inputElement.dispatchEvent(new Event('change'))
  }

  #formatValue () {
    if (this.inputElement.dataset.format === 'identity-number') {
      return this.#formatIdentityNumber()
    }

    return this.#formatNumber()
  }

  #formatNumber () {
    while (this.currentValue.length > 1 && this.currentValue[0] === '0') {
      this.currentValue.shift()
    }

    const decimals = parseInt(this.inputElement.dataset.decimals || 2)
    const value = Array.from(this.currentValue)

    if (decimals > 0) {
      while (value.length < (decimals + 1)) {
        value.unshift('0')
      }

      value.splice(-decimals, 0, this.separatorValue)
    }

    return value.join('')
  }

  #formatIdentityNumber () {
    const value = Array.from(this.currentValue)
    const prefix = value.slice(0, 2).join('')
    const digits = prefix === '19' || prefix === '20' ? 12 : 10

    if (value.length > (digits - 4)) {
      value.splice(digits - 4, 0, '-')
    }

    return value.join('')
  }

  // this function allows to trigger a blur event on the input each time we click on
  // the numpad, so that we can attach a listener and do some actions (like transform the data)
  #focusBlur (method) {
    if (!this.inputElement) return

    this.inputElement.focus()
    method()
    this.inputElement.blur()
    this.element.focus()
  }
}
